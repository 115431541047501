import type { StandardTextFieldProps } from '@mui/material';
import { TextField } from '@mui/material';
import type { FormikProps } from 'formik';
import { getIn } from 'formik';
import { useCallback } from 'react';
import * as React from 'react';

export type NestedKeysToObject<K extends string> =
  K extends `${infer T}.${infer U}`
    ? { [key in T]: NestedKeysToObject<U> }
    : { [key in K]?: unknown };

interface IProps<T extends string, V> extends StandardTextFieldProps {
  id?: string;
  name: T;
  placeholder?: string;
  formik: FormikProps<V>;

  capitalize?: boolean;
  trim?: boolean;
}

export const FTextInput = <
  FieldName extends string,
  V extends NestedKeysToObject<FieldName>
>({
  name,
  formik,
  children,
  ...props
}: IProps<FieldName, V>): JSX.Element => {
  const normalizeOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const originalValue = getIn(formik.values, e.target.name);
      let value = originalValue;
      if (value === undefined) {
        return;
      }

      if (props.trim) {
        value = value?.trim();
      }

      if (props.capitalize) {
        value = value?.charAt(0).toUpperCase() + value?.slice(1);
      }

      if (value !== originalValue) {
        formik.setFieldValue(e.target.name, value);
      }

      formik.handleBlur(e);
    },
    [formik, props.trim, props.capitalize]
  );

  return (
    <TextField
      name={name}
      value={getIn(formik.values, name) ?? null}
      onChange={formik.handleChange}
      onBlur={normalizeOnBlur}
      disabled={formik.isSubmitting}
      helperText={getIn(formik.touched, name) && getIn(formik.errors, name)}
      error={Boolean(getIn(formik.errors, name) && getIn(formik.touched, name))}
      {...props}
    />
  );
};
